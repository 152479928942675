@import '../../../styles/variables';

.home-page {
  .introduction-panel {
    min-height: 100vh;
    .introduction-content {
      color: $secondary;
      max-width: 900px;

      .description {
        margin-top: 24px;
        text-align: center;
      }
    }
  }

  button {
    width: 280px;
  }
}
